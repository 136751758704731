import React from "react";
import Styles from "./ExamRewards.module.scss";
import imgText from "../../../assets/exam/reward_text.svg";
import deskImg from "../../../assets/exam/reward_desk.svg";
import mobImg from "../../../assets/exam/reward_mob.svg";
const ExamRewards = ({ data }) => {
  return (
    <>
      <section className={Styles.examReward}>
        <div className={Styles.examReward_title}>
          <div className="hidden">
            <div data-aos="fade-up">Rewards</div>
          </div>
          <div className={Styles.examReward_title_img}>
            <img src={imgText} alt="textimg" />
          </div>
        </div>

        <div className={Styles.examReward_prize}>
          <div className={`${Styles.examReward_prize_box} ${Styles.box1}`}>
            <span>3rd prize</span>Rs. {data?.rewards?.prize3 || "-"}<span className={Styles.text3}>One Winner</span>
          </div>
          <div className={`${Styles.examReward_prize_box} ${Styles.box2}`}>
            <span>1st prize</span>Rs. {data?.rewards?.prize1 || "-"}<span className={Styles.text3}>One Winner</span>
          </div>
          <div className={`${Styles.examReward_prize_box} ${Styles.box3}`}>
            <span>2nd prize</span>Rs. {data?.rewards?.prize2 || "-"}<span className={Styles.text3}>One Winner</span>
          </div>

          <picture className={Styles.examReward_prize_img}>
            <source media="(max-width:767px)" srcSet={mobImg} alt="mob_img" />
            <source media="(min-width:768px)" srcSet={deskImg} alt="desk_img" />
            <img src={deskImg} alt="desk_img" />
          </picture>
        </div>
      </section>
    </>
  );
};

export default ExamRewards;
