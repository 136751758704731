import winner1 from "../../../assets/winners/13thExamWinners/1st Winner.svg";
import winner2 from "../../../assets/winners/13thExamWinners/2nd Winner.png";
import winner3 from "../../../assets/winners/13thExamWinners/3rd Winner.png";
import winnerP1 from "../../../assets/winners/13thExamWinners/1st Arpita More.jpeg";
import winnerP2 from "../../../assets/winners/13thExamWinners/2nd Hardik Rane.jpg";
import winnerP3 from "../../../assets/winners/13thExamWinners/2nd Punam Ambule.jpeg";
import winnerP4 from "../../../assets/winners/13thExamWinners/3rd Bhavesh.jpg";
import winnerP5 from "../../../assets/winners/13thExamWinners/3rd Ganesh.jpg";
import winnerP6 from "../../../assets/winners/13thExamWinners/3rd Shaikh Soha.jpeg";
import winnerGaurav from "../../../assets/winners/w3.svg";

import refferal1 from "../../../assets/winners/refferal1.svg";
import refferal1V2 from "../../../assets/winners/refferal1v2.png";
import refferal2 from "../../../assets/winners/refferal2.svg";
import refferal3 from "../../../assets/winners/refferal3.svg";
import kaluImg from "../../../assets/winners/kalu_img.png";
import gautam from "../../../assets/winners/gautam.png";
import pappu from "../../../assets/winners/pappu.png";
import kalu from "../../../assets/winners/kalu.png";
import umesh from "../../../assets/winners/umesh.png";
import savitraWaghe from "../../../assets/winners/savitra waghe.svg";
import savitraWaghePerson from "../../../assets/winners/Savitra_Waghe-person.svg";
import defaultImage from "../../../assets/winners/default-image.svg";

export const tabNames = [
  {
    id: 0,
    title: "Champions",
  },
  {
    id: 1,
    title: "Scholarship Winners",
  },
  {
    id: 2,
    title: "Task Toppers",
  },
  {
    id: 3,
    title: "Top Referrals",
  },
];

export const leaderBoardData = {
  tab1: [
    {
      id: 0,
      profile: winnerGaurav,
      image: winnerGaurav,
      name: "Gaurav Sadgir",
      per: "",
      amount: "Scholarship Achiever",
      college: "Podar International School",
      rank: 1,
    },
    {
      id: 1,
      profile: savitraWaghePerson,
      image: savitraWaghe,
      name: "Savitra Waghe",
      per: "",
      amount: "Scholarship Achiever",
      college:
        "Dr. D.Y. Patil Institute of Engineering, Management and Research College in Pimpri-Chinchwad, Maharashtra",
      rank: 2,
    },
    {
      id: 2,
      profile: refferal1,
      image: refferal1V2,
      name: "Gautam Dhonde",
      per: "",
      amount: "Top Referral",
      college: "",
      rank: 3,
    },
  ],
  tab2: [
    {
      id: 0,
      profile: winnerP1,
      image: winner1,
      name: "Arpita Sunil More" ,
      per: "100%",
      amount: "₹11,100",
      college: "Shri Shivshankar Vidyalaya , Ural Bk , Taluka Balapur Dist Akola",
      rank: 1,
    },
    {
      id: 1,
      profile: winnerP2,
      image: winner2,
      name: "Hardik Ganpati Rane",
      per: "99.97%",
      amount: "₹7,100",
      college: "Dr. Shivajirao Kadam College of Pharmacy, Kasabe Digraj, Tq- Miraj Dist- Sangali",
      rank: 2,
    },
    {
      id: 2,
      profile: winnerP3,
      image: winnerP3,
      name: "Punam Vijay Ambule ",
      per: "99.94%",
      amount: "₹7,100",
      college: "J D College Of Engineering And Management Nagpur",
      rank: 2,
    },
    {
      id: 3,
      profile: winnerP4,
      image: winner3,
      name: "Bhavesh Patil Sanjay ",
      per: "99.91%",
      amount: "₹5,100",
      college: "D M Patil uchha Madhyamik vidyalay Savkhede Turk Taluka Parola District Jalgaon",
      rank: 3,
    },
    {
      id: 4,
      profile: winnerP5,
      image: winnerP5,
      name: "Ganesh Ashok Aher",
      per: "99.88%",
      amount: "₹5,100",
      college: "Karmaveer Ramraoji Aher Mahavidyalay, Deola, Nashik",
      rank: 3,
    },
    {
      id: 5,
      profile: winnerP6,
      image: winnerP6,
      name: "Soha Parvez Shaikh",
      per: "99.85%",
      amount: "₹5,100",
      college: "Bhavans College munshi nagar, andheri west, mumbai",
      rank: 3,
    },
  ],
  tab3: [
    {
      id: 0,
      profile: defaultImage,
      image: defaultImage,
      name: "",
      per: "",
      amount: "",
      college: "",
      rank: 1,
    },
    {
      id: 1,
      profile: defaultImage,
      image: defaultImage,
      name: "",
      per: "",
      amount: "",
      college: "",
      rank: 2,
    },
    {
      id: 2,
      profile: defaultImage,
      image: defaultImage,
      name: "",
      per: "",
      amount: "",
      college: "",
      rank: 3,
    },
  ],
  tab4: [
    {
      id: 0,
      profile: gautam,
      image: refferal1,
      name: "Gautam Dhonde",
      per: "",
      amount: "3200+",
      college: "Gautam Dhonde",
      rank: 1,
    },
    {
      id: 1,
      profile: pappu,
      image: refferal2,
      name: "Pappu Ramteke",
      per: "",
      amount: "950+",
      college: "Pappu Ramteke",
      rank: 2,
    },
    {
      id: 2,
      profile: kalu,
      image: kaluImg,
      name: "Kalu Bhagat",
      per: "",
      amount: "800+",
      college: "Kalu Bhagat",
      rank: 3,
    },
    {
      id: 3,
      profile: umesh,
      name: "Umesh Wankhede",
      per: "",
      amount: "600+",
      rank: 4,
    },
    {
      id: 4,
      profile: defaultImage,
      name: "Ram Katade",
      per: "",
      amount: "500+",
      rank: 5,
    },
    {
      id: 5,
      profile: defaultImage,
      name: "Avinash Ghodke",
      per: "",
      amount: "300+",
      rank: 6,
    },
    {
      id: 6,
      profile: defaultImage,
      name: "Gayatri Bhor",
      per: "",
      amount: "210+",
      rank: 7,
    },
    {
      id: 7,
      profile: defaultImage,
      name: "Kisan Harinkhede",
      per: "",
      amount: "200+",
      rank: 8,
    },
    {
      id: 8,
      profile: defaultImage,
      name: "Madhav Kamble",
      per: "",
      amount: "150+",
      rank: 9,
    },
    {
      id: 9,
      profile: defaultImage,
      name: "Amit Patekar",
      per: "",
      amount: "100+",
      rank: 10,
    },
  ],
};
