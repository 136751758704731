import React, { useState } from "react";
import logo from "../../assets/scholar-adda-logo.svg";
import storeImg from "../../assets/home/play_logo.svg";
import "../../css/Navbar.css";
import { NavLink } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Marquee from "react-marquee-slider";
import { NavbarProgramDate, NavbarProgramName } from "../../Utils/Contanst";
import HoverDropdown from "./HoverDropdown/HoverDropdown";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const ExamsLinks = [
    { label: "Upcoming", url: "/exams#upcoming_exam" },
    { label: "Previous", url: "/exams#upcoming_exam" },
  ];
  const ExamsLinksMob = [
    { label: "Exams", url: "/exams" },
    { label: "Upcoming", url: "/exams#upcoming_exam" },
    { label: "Previous", url: "/exams#upcoming_exam" },
  ];

  const AboutUsLinks = [
    { label: "Introduction", url: "/about-us#intro" },
    { label: "Vision & Mission", url: "/about-us#vision" },
    // { label: "Problems", url: "/about-us#problems" },
    // { label: "Solutions", url: "/about-us#solutions" },
    // { label: "Features", url: "/about-us#features" },
    { label: "Traction", url: "/about-us#traction" },
    { label: "Team", url: "/about-us#au_team" },
  ];
  const AboutUsLinksMob = [
    { label: "About Us", url: "/about-us" },
    { label: "Introduction", url: "/about-us#intro" },
    { label: "Vision & Mission", url: "/about-us#vision" },
    // { label: "Problems", url: "/about-us#problems" },
    // { label: "Solutions", url: "/about-us#solutions" },
    // { label: "Features", url: "/about-us#features" },
    { label: "Traction", url: "/about-us#traction" },
    { label: "Team", url: "/about-us#au_team" },
  ];

  const CommunityLinks = [
    { label: "Ambassador Program", url: "/brand-ambassador-program" },
    { label: "Referral Program", url: "/referral" },
    { label: "Blogs", url: "/blogs" },
    { label: "Careers", url: "/career" },
  ];

  const handleJoinTwitter = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
      "_blank",
      "noopener,noreferrer"
    );
    handleLinkClick();
  };
  return (
    <div className="Navbar-Container">
      {/* <div
        style={{
          background:
            "linear-gradient(111.16deg, #FF6700 36.05%, #EB7E08 69.27%, #FFE500 94.58%)",
          height: "30px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Marquee velocity={50} minScale={0.5} resetAfterTries={200}>
          <div className="Navbar-Slider-Container">
            <span className="topYellowText">{NavbarProgramDate} - </span>
            <span className="topWhiteText">{NavbarProgramName}</span>
            <span className="topWhiteText" style={{ margin: "0 25px" }}>
              {" "}
              .{" "}
            </span>
            <span className="topYellowText">{NavbarProgramDate} - </span>
            <span className="topWhiteText">{NavbarProgramName}</span>
            <span className="topWhiteText" style={{ margin: "0 25px" }}>
              {" "}
              .{" "}
            </span>
          </div>
          <div></div>
        </Marquee>
      </div> */}

      {/* desk navabar */}
      <div className="navbar mob_hide deskNavPad">
        <NavLink
          to="/"
          onClick={handleLinkClick}
        >
          <img src={logo} alt="logo" className="logo" />
        </NavLink>

        <div className="titles">
          <NavLink
            to="/home"
            activeClassName="selected"
            className="navLink"
            onClick={handleLinkClick}
          >
            Home
          </NavLink>
          {/* <NavLink
            to="/exams"
            activeClassName="selected"
            className="navLink"
            onClick={handleLinkClick}
          >
            Exams
          </NavLink> */}
          <HoverDropdown
            title="Exams"
            titleLink="/exams"
            links={ExamsLinks}
            setMenuOpen={setMenuOpen}
          />
          {/* <NavLink
            to="/about-us"
            activeClassName="selected"
            className="navLink"
          >
            About Us
          </NavLink> */}
          <HoverDropdown
            title="About Us"
            titleLink="/about-us"
            links={AboutUsLinks}
            setMenuOpen={setMenuOpen}
          />
          {/* <NavLink
            to="/brand-ambassador-program"
            activeClassName="selected"
            className="navLink"
          >
            Community
          </NavLink> */}
          <HoverDropdown
            title="Community"
            titleLink="/brand-ambassador-program"
            links={CommunityLinks}
            setMenuOpen={setMenuOpen}
          />
          {/* <NavLink
            to="/how-it-works"
            activeClassName="selected"
            className="navLink"
          >
            How it Works
          </NavLink> */}
          <NavLink
            to="/contactus"
            activeClassName="selected"
            className="navLink"
            onClick={handleLinkClick}
          >
            Contact Us
          </NavLink>
          {/* <NavLink
            to="/brand-ambassador-program"
            activeClassName="selected"
            className="navLink"
          >
            Ambassador Program
          </NavLink> */}
          {/* <NavLink to="/legal" activeClassName="selected" className="navLink">
            Legal
          </NavLink> */}
          {/* <NavLink
            to="/access-platform"
            activeClassName="selected"
            className="navLink"
          >
            Access Platform
          </NavLink> */}
          {/* <NavLink to="/career" activeClassName="selected" className="navLink">
            Career
          </NavLink> */}
        </div>

        {/* join tweeter desk */}
        <div>
          <Box minWidth="176px">
            <Button
              className="discordIcon"
              variant="contained"
              sx={{
                display: "flex",
                padding: "10px 20px",
                borderRadius: "25px",
                // backgroundColor: 'transparent',
                background:
                  "linear-gradient(111.16deg, #FF6700 36.05%, #EB7E08 69.27%, #FFE500 94.58%)",
                color: "black",
                boxShadow: "none",
                border: "1px solid #FFE500",
                ":hover": { bgcolor: "transparent" },
                gap: "11px",
                // ml: 'auto',
                width: "auto",
                maxWidth: "300px",
              }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <Typography
                className="discord"
                // display="none"
                fontSize={12}
                fontWeight={400}
                fontFamily="Krona One"
                color="white"
                noWrap
              >
                Download Now
              </Typography>
              <img
                src={storeImg}
                alt="storeImg"
                style={{ height: "25px", width: "25px" }}
              />
            </Button>
          </Box>
        </div>
      </div>

      {/* Mobile navbar */}

      <div className="desk_hide">
        <header class="header">
          <a href="/" class="logo">
            <img src={logo} alt="logo" className="logo" />
          </a>
          <input
            class="menu-btn"
            type="checkbox"
            id="menu-btn"
            checked={menuOpen}
            onChange={() => setMenuOpen(!menuOpen)}
          />
          <label class="menu-icon" for="menu-btn">
            <span class="navicon"></span>
          </label>
          <ul class="menu">
            <li>
              <NavLink
                to="/home"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Home
              </NavLink>
            </li>
            <li>
              {" "}
              {/* <NavLink
                to="/exams"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Exams
              </NavLink>
              <div className="nav_sublinks">
                <a href="/exams#upcoming_exam">Upcoming</a>
                <a href="/exams#upcoming_exam">Previous</a>
              </div> */}
              <HoverDropdown
                title="Exams"
                titleLink="/exams"
                links={ExamsLinksMob}
                setMenuOpen={setMenuOpen}
              />
            </li>
            <li>
              {" "}
              {/* <NavLink
                to="/about-us"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                About Us
              </NavLink>
              <div className="nav_sublinks">
                <a href="/about-us">Introduction </a>
                <a href="/about-us#mission">Vison</a>
                <a href="/about-us#mission">Mission</a>
                <a href="/about-us#problems">Problems</a>
                <a href="/about-us#solutions">Solutions</a>
                <a href="/about-us#features">Features</a>
                <a href="/about-us#au_team">Team</a>
              </div> */}
              <HoverDropdown
                title="About Us"
                titleLink="/about-us"
                links={AboutUsLinksMob}
                setMenuOpen={setMenuOpen}
              />
            </li>
            <li>
              {" "}
              {/* <NavLink
                to="/exams"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Community
              </NavLink>
              <div className="nav_sublinks">
                <a href="/brand-ambassador-program">Become Ambassador </a>
                <a href="/referral">Referral Program</a>
                <a href="/blogs">Blogs</a>
                <a href="/career">Careers</a>
              </div> */}
              <HoverDropdown
                title="Community"
                titleLink="/brand-ambassador-program"
                links={CommunityLinks}
                setMenuOpen={setMenuOpen}
              />
            </li>
            {/* <li>
              {" "}
              <NavLink
                to="/about-us"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                About Us
              </NavLink>
             
            </li> */}
            {/* <li>
              <NavLink
                to="/how-it-works"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                How it Works
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/contactus"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Contact Us
              </NavLink>
            </li>
            {/* <li>
              {" "}
              <NavLink
                to="/brand-ambassador-program"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Ambassador Program
              </NavLink>
            </li> */}
            {/* <li>
              {" "}
              <NavLink
                to="/legal"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Legal
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink
                to="/access-platform"
                activeClassName="selected"
                className="navLink"
                onClick={handleLinkClick}
              >
                Access Platform
              </NavLink>
            </li> */}

            <li>
              <Box minWidth="176px">
                <Button
                  className="discordIcon"
                  variant="contained"
                  maxWidth="300px"
                  sx={{
                    display: "flex",
                    padding: "10px 20px",
                    borderRadius: "25px",
                    // backgroundColor: 'transparent',
                    background:
                      "linear-gradient(111.16deg, #FF6700 36.05%, #EB7E08 69.27%, #FFE500 94.58%)",
                    color: "black",
                    boxShadow: "none",
                    border: "1px solid #FFE500",
                    ":hover": { bgcolor: "transparent" },
                    gap: "11px",
                    // ml: 'auto',
                    width: "auto",
                    maxWidth: "300px",
                  }}
                  onClick={handleJoinTwitter}
                >
                  <Typography
                    className="discord"
                    // display="none"
                    fontSize={12}
                    fontWeight={400}
                    fontFamily="Krona One"
                    color="white"
                    noWrap
                  >
                    Download Now
                  </Typography>
                  <img
                    src={storeImg}
                    alt="storeImg"
                    style={{ height: "25px", width: "25px" }}
                  />
                </Button>
              </Box>
            </li>
          </ul>
        </header>
      </div>
    </div>
  );
}

export default Navbar;
