import examBanner_10th from "../../../assets/exam/Banners/Babasaheb Ambedkar Exam Marathi 1920.jpg";
import examBanner_11th from "../../../assets/exam/Banners/11th Chh. Sambhaji Maharaj Exam2.jpg";
import examBanner_12th from "../../../assets/exam/Banners/12th Chh. Shahu maharaj exam1.jpg";
import examBanner_13th from "../../../assets/exam/Banners/13th Lokmanya Tilak Scholarship Exam.jpg";
import examBanner_14th from "../../../assets/exam/Banners/14 Independence day Exam.jpg";
import examBanner_15th from "../../../assets/exam/Banners/15th Teachers day Exam.jpg";
import examBanner_16th from "../../../assets/exam/Banners/16th Mahatma Gandhi Exam.jpg";
import examBanner_17th from "../../../assets/exam/Banners/Constitution Day Exam.jpg";
import examBanner_18th from "../../../assets/exam/Banners/Indian Culture and Current Affairs Exam English.jpg";
import examBanner_19th from "../../../assets/exam/Banners/Republic Day Exam.jpg";
import defaultImage from "../../../assets/exam/pw3.svg";
import w81 from "../../../assets/exam/prevWinnners/81.png";
import w82 from "../../../assets/exam/prevWinnners/82.png";
import w83 from "../../../assets/exam/prevWinnners/83.png";
import w71 from "../../../assets/exam/prevWinnners/71.jpeg";
import w72 from "../../../assets/exam/prevWinnners/72.jpeg";
import w73 from "../../../assets/exam/prevWinnners/73.jpeg";
import w61 from "../../../assets/exam/prevWinnners/61.jpeg";
import w62 from "../../../assets/exam/prevWinnners/62.jpeg";
import w63 from "../../../assets/exam/prevWinnners/63.jpeg";
import w51 from "../../../assets/exam/prevWinnners/51.jpeg";
import w52 from "../../../assets/exam/prevWinnners/52.jpeg";
import w53 from "../../../assets/exam/prevWinnners/53.jpeg";
import w41 from "../../../assets/exam/prevWinnners/41.jpeg";
import w42 from "../../../assets/exam/prevWinnners/42.jpeg";
import w43 from "../../../assets/exam/prevWinnners/43.jpeg";
import w31 from "../../../assets/exam/prevWinnners/31.jpeg";
import w32 from "../../../assets/exam/prevWinnners/32.jpeg";
import w33 from "../../../assets/exam/prevWinnners/33.jpeg";
import w21 from "../../../assets/exam/prevWinnners/21.jpeg";
import w22 from "../../../assets/exam/prevWinnners/22.jpeg";
import w23 from "../../../assets/exam/prevWinnners/23.jpeg";
import w11 from "../../../assets/exam/prevWinnners/11.jpeg";
import w12 from "../../../assets/exam/prevWinnners/12.jpeg";
import w13 from "../../../assets/exam/prevWinnners/13.jpeg";
import w91 from "../../../assets/exam/prevWinnners/91.jpg";
import w92 from "../../../assets/exam/prevWinnners/92.jpg";
import w93 from "../../../assets/exam/prevWinnners/93.jpg";

import w101 from "../../../assets/exam/prevWinnners/101.jpeg";
import w102 from "../../../assets/exam/prevWinnners/102.jpeg";
import w103 from "../../../assets/exam/prevWinnners/103.jpg";

import w161 from "../../../assets/exam/prevWinnners/161.jpeg";
import w162 from "../../../assets/exam/prevWinnners/162.jpeg";
import w163 from "../../../assets/exam/prevWinnners/163.jpeg";

import w171 from "../../../assets/exam/prevWinnners/171.jpeg";
import w172 from "../../../assets/exam/prevWinnners/172.jpg";
import w173 from "../../../assets/exam/prevWinnners/173.jpeg";

import w181 from "../../../assets/exam/prevWinnners/181.jpg";
import w182 from "../../../assets/exam/prevWinnners/182.jpg";
import w183 from "../../../assets/exam/prevWinnners/183.jpg";

import w191 from "../../../assets/exam/prevWinnners/191.jpg";
import w192 from "../../../assets/exam/prevWinnners/192.jpg";
import w193 from "../../../assets/exam/prevWinnners/193.jpg";

export const ExamData = {
  upcoming: [
    {
      id: 14,
      title: "Indian Culture & Curent Affairs Scholarship Exam",
      examNumber: 18,
      banner: examBanner_18th,
      details: {
        eligibility: "Anyone Can Participate",
        subjects: "Current Affairs and Indian Culture",
        registrationdeadline: "25th December 2024 3:30 PM",
        format: "Multiple Choice Questions",
        totalquestions: "50",
        totalmarks: "100",
        examdate: "25th December 2024",
        examtime: "4 PM to 5 PM",
        duration: "60",
      },
      rewards: {
        prize1: "7,000",
        prize2: "5,000",
        prize3: "3,000",
      },
      label: "Notify",
    },
    {
      id: 15,
      title: "Republic Day Scholarship Exam",
      examNumber: 19,
      banner: examBanner_19th,
      details: {
        eligibility: "Anyone Can Participate",
        subjects: "Current Affairs and Genral Knowledge",
        registrationdeadline: "26th Jan 2024 3:30 PM",
        format: "Multiple Choice Questions",
        totalquestions: "50",
        totalmarks: "100",
        examdate: "26th Jan 2024",
        examtime: "4 PM to 5 PM",
        duration: "60",
      },
      rewards: {
        prize1: "100,000",
        prize2: "51,000",
        prize3: "21,000",
      },
      label: "Notify",
    },
  ],
  ongoing: [
      {
      id: 13,
      title: "Constitution Day Scholarship Exam",
      examNumber: 17,
      banner: examBanner_17th,
      details: {
        eligibility: "Anyone Can Participate",
        subjects: "Genral Knowledge and Indian History",
        registrationdeadline: "30th November 2024 5:30 PM",
        format: "Multiple Choice Questions",
        totalquestions: "50",
        totalmarks: "100",
        examdate: "30th November 2024",
        examtime: "6 PM to 7PM",
        duration: "60",
      },
      rewards: {
        prize1: "100,000",
        prize2: "21,000",
        prize3: "11,000",
        prize4: "2,000",
        prize5: "1,000",
        prize6: "500",
      },
      label: "Notify",
    },
    // {
    //   id: 12,
    //   title: "Mahatma Gandhi Jayanti Scholarship Exam",
    //   examNumber: 16,
    //   banner: examBanner_16th,
    //   details: {
    //     eligibility: "Anyone Can Participate",
    //     subjects: "Genral Knowledge and Indian History",
    //     registrationdeadline: "2nd October 2024 3:30 PM",
    //     format: "Multiple Choice Questions",
    //     totalquestions: "50",
    //     totalmarks: "100",
    //     examdate: "2nd October 2024",
    //     examtime: "4 PM to 5 PM",
    //     duration: "60",
    //   },
    //   rewards: {
    //     prize1: "7,000",
    //     prize2: "5,000",
    //     prize3: "3,000",
    //   },
    //   label: "Notify",
    // },
    // {
    //   id: 11,
    //   title: "Teachers Day Special Scholarship Exam",
    //   examNumber: 15,
    //   banner: examBanner_15th,
    //   details: {
    //     eligibility: "Anyone Can Participate",
    //     subjects: "Genral Knowledge and Current Affairs",
    //     registrationdeadline: "8th September 2024 3:30 PM",
    //     format: "Multiple Choice Questions",
    //     totalquestions: "50",
    //     totalmarks: "100",
    //     examdate: "8th September 2024",
    //     examtime: "4 PM to 5 PM",
    //     duration: "60",
    //   },
    //   rewards: {
    //     prize1: "7,000",
    //     prize2: "5,000",
    //     prize3: "3,000",
    //   },
    //   label: "Notify",
    // },
    // {
    //   id: 4,
    //   title: "Special Scholarship Exam On Independence Day",
    //   examNumber: 14,
    //   banner: examBanner_14th,
    //   details: {
    //     eligibility: "Anyone Can Participate",
    //     subjects: "Indian History, Economy, Current Affairs",
    //     registrationdeadline: "25 August 2024 3:30 PM",
    //     format: "Multiple Choice Questions",
    //     totalquestions: "50",
    //     totalmarks: "100",
    //     examdate: "25 August 2024",
    //     examtime: "4 PM to 5 PM",
    //     duration: "60",
    //   },
    //   rewards: {
    //     prize1: "11,100",
    //     prize2: "7,100",
    //     prize3: "5,100",
    //   },
    //   label: "Notify",
    // },
  ],
  previous: [
    {
      id: 19,
      title: "Mahatma Gandhi Jayanti Scholarship Exam",
      date: "2nd Oct 2024",
      list: [
        {
          id: 0,
          profile: w191,
          winner: "Sumit Dange Sandip",
        },
        {
          id: 1,
          profile: w192,
          winner: "Indrajit Kadam Balaji",
        },
        {
          id: 3,
          profile: w193,
          winner: "Kunal Chavhan Sunil",
        },
      ],
    },
    {
      id: 18,
      title: "Teachers Day Scholarship Exam",
      date: "5th Sep 2024",
      list: [
        {
          id: 0,
          profile: w181,
          winner: "Pratik Jadhao Raju",
        },
        {
          id: 1,
          profile: w182,
          winner: "Rameshwar Sadhal Harichandra",
        },
        {
          id: 3,
          profile: w183,
          winner: "Dhammajyoti Sahare Pravin",
        },
      ],
    },
    {
      id: 17,
      title: "Independence Day Special Scholarship Exam",
      date: "25th Aug 2024",
      list: [
        {
          id: 0,
          profile: w171,
          winner: "Arpita More",
        },
        {
          id: 1,
          profile: w172,
          winner: "Hardik Rane",
        },
        {
          id: 3,
          profile: w173,
          winner: "Bhavesh Patil",
        },
      ],
    },
    {
      id: 16,
      title: "Lokmanya Tilak & Annabhau Sathe Scholarship Exam",
      date: "28th July 2024",
      list: [
        {
          id: 0,
          profile: w161,
          winner: "Swati Gaikwad",
        },
        {
          id: 1,
          profile: w162,
          winner: "Priya Tompe",
        },
        {
          id: 3,
          profile: w163,
          winner: "Shivkumar Holgir",
        },
      ],
    },
    {
      id: 10,
      title: "Chhatrapati Shahu Maharaj Scholarship Exam",
      date: "30 June 2024",
      list: [
        {
          id: 0,
          profile: w101,
          winner: "Krishna Sadgir",
        },
        {
          id: 1,
          profile: w102,
          winner: "Shaikh Arshiya",
        },
        {
          id: 3,
          profile: w103,
          winner: "Rohan Birajdar",
        },
      ],
    },
    {
      id: 9,
      title:
        "Mahatma Jyotiba Phule & Dr. Babasaheb Ambedkar Scholarship Exam",
      date: "14 April 2024",
      list: [
        {
          id: 0,
          profile: w91,
          winner: "Swarup Jadhav",
        },
        {
          id: 1,
          profile: w92,
          winner: "Aditya Tiwari",
        },
        {
          id: 3,
          profile: w93,
          winner: "Nikhil Gaikwad",
        },
      ],
    },
    {
      id: 8,
      title: "Chh. Shivaji Maharaj Memorial Scholarly Challenge",
      date: "19 February 2024",
      list: [
        {
          id: 0,
          profile: w81,
          winner: "Miss. Akaanksha Sonawane",
        },
        {
          id: 1,
          profile: w82,
          winner: "Miss. Waghe Savitra Madhav",
        },
        {
          id: 3,
          profile: w83,
          winner: "Mr. Gaurav Sanjay Sadgir",
        },
      ],
    },
    {
      id: 7,
      title: "7th Scholarship Exam",
      date: "4 February 2024",
      list: [
        {
          id: 0,
          profile: w71,
          winner: "Mr. Ranvir Jitendrasingh Chauhan",
        },
        {
          id: 1,
          profile: w72,
          winner: "Miss. Thorat Jyoti",
        },
        {
          id: 3,
          profile: w73,
          winner: "Miss. Bhagyshree Jagtap",
        },
      ],
    },
    {
      id: 6,
      title: "6th Scholarship Exam",
      date: "7 January 2024",
      list: [
        {
          id: 0,
          profile: w61,
          winner: "Miss. Waghe Savitra Madhav",
        },
        {
          id: 1,
          profile: w62,
          winner: "Miss. Mahima Yogeshwar Admane",
        },
        {
          id: 3,
          profile: w63,
          winner: "Mr. Darshan Kalpesh Rakhecha",
        },
      ],
    },
    {
      id: 5,
      title: "5th Scholarship Exam",
      date: "25 December 2023",
      list: [
        {
          id: 0,
          profile: w51,
          winner: "Mr. Gaurav Sanjay Sadgir",
        },
        {
          id: 1,
          profile: w52,
          winner: "Mr. Falendra Dhananjay Harinkhede",
        },
        {
          id: 3,
          profile: w53,
          winner: "Miss. Trupti ravindra gatir",
        },
      ],
    },
    {
      id: 4,
      title: "4th Scholarship Exam",
      date: "17 December 2023",
      list: [
        {
          id: 0,
          profile: w41,
          winner: "Miss. Roshni Rajkumar Lilhare",
        },
        {
          id: 1,
          profile: w42,
          winner: "Miss. Dhanshree Yuvraj Kshirsagar",
        },
        {
          id: 3,
          profile: w43,
          winner: "Miss. Kinjal Jayram Gohil",
        },
      ],
    },
    {
      id: 3,
      title: "3th Scholarship Exam",
      date: "26 November 2023",
      list: [
        {
          id: 0,
          profile: w31,
          winner: "Mr. Swaraj Suresh Kajale",
        },
        {
          id: 1,
          profile: w32,
          winner: "Miss. Sakshi Sunil Rokade",
        },
        {
          id: 3,
          profile: w33,
          winner: "Miss. Tanaya Deshbhratar",
        },
        // {
        //   id: 4,
        //   profile: defaultImage,
        //   winner: "Mr. Naman Prashant Picha",
        // },
        // {
        //   id: 5,
        //   profile: defaultImage,
        //   winner: "Miss. Poorwa Pitamlal Sonwane",
        // },
      ],
    },
    {
      id: 2,
      title: "2th Scholarship Exam",
      date: "22 October 2023",
      list: [
        {
          id: 0,
          profile: w21,
          winner: "Miss. Pranjal Prakash Kokane",
        },
        {
          id: 1,
          profile: w22,
          winner: "Mr. Safal Pankaj Lunawat",
        },
        {
          id: 3,
          profile: w23,
          winner: "Miss. Gaytri Ashok barhe",
        },
        // {
        //   id: 4,
        //   profile: defaultImage,
        //   winner: "Mr. Rushikesh Eknath Sabale",
        // },
        // {
        //   id: 5,
        //   profile: defaultImage,
        //   winner: "Miss. Sakshi Kailas Walzade",
        // },
        // {
        //   id: 6,
        //   profile: defaultImage,
        //   winner: "Miss. Pragati Mahesh Jadhav",
        // },
      ],
    },
    {
      id: 1,
      title: "1th Scholarship Exam",
      date: "2 October 2023",
      list: [
        {
          id: 0,
          profile: w11,
          winner: "Mr. Navnath Sudam Barhe",
        },
        {
          id: 1,
          profile: w12,
          winner: "Miss. Shubhangi Jagannath Sabale",
        },
        {
          id: 3,
          profile: w13,
          winner: "Miss. Gayatri Mahendra Lokhande ",
        },
        // {
        //   id: 4,
        //   profile: defaultImage,
        //   winner: "Miss. Yash Santosh Bhosale",
        // },
      ],
    },
  ],
};
